import { useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const SignCanvas = (props: any) => {
  const {
    signee,
    termsId,
    contractsData,
    userIp,
    oppID,
    termsValid,
    setTermsValid,
    sign,
    setSignValid,
    signValid,
    setShowAdoptModal,
  } = props;
  const { name, email, phone } = signee;
  const [save, setSave] = useState(false);
  let contractObj: any = {
    id: '',
    directDebitAccepted: true,
    signees: [],
  };
  const clearSign = (signObj: any) => {
    signObj.current.clear();
    setSignValid('');
  };
  const saveSign = (signObj: any) => {
    let terms = document.getElementById(termsId) as HTMLInputElement;
    if (!terms.checked) {
      setTermsValid('Rejected');
      return;
    }
    if (signObj.current.isEmpty()) {
      setSignValid('Rejected');
      return;
    }
    let userObj = {
      id: '',
      name: name,
      email: email,
      phone: phone,
      isSigned: false,
      signedTime: '',
      ip: userIp,
      userAgent: navigator.userAgent,
      signature: '',
    };
    let signData = '';
    if (terms.checked) {
      signData = signObj.current.getTrimmedCanvas().toDataURL();

      userObj.signature = signData.slice(signData.indexOf(',') + 1);
      userObj.isSigned = true;
      userObj.signedTime = new Date().toISOString();
      console.log('userObj', userObj);
      let contractsArray: any = [];

      contractsData?.contracts.forEach((con: any, index: number) => {
        let newConObj = { ...contractObj };
        newConObj.id = con.id;
        newConObj.signees = [];
        con.signees.forEach((user: any) => {
          let newUser = { ...userObj };
          if (
            user.oppContactId === props.currentUserID &&
            user.isSigned === false
          ) {
            newUser.id = user.id;
            newConObj.signees.push(newUser);
            contractsArray.push(newConObj);
          }
        });
      });
      setSave(true);
      props.saveAndContinue({
        opportunityID: oppID,
        contracts: contractsArray,
      });
    }
  };

  return (
    <div className='mb-2 max-w-[400px]'>
      <div className='card mb-2'>
        <div className='flex p-2 border-bottom'>
          <h6 className='flex-grow-1 mt-2 mb-1 ms-1'>Draw Signature</h6>
          {!save && (
            <>
              <button
                className='btn btn-outline-secondary me-2 btn-sm'
                onClick={() => {
                  clearSign(sign);
                }}
              >
                Clear
              </button>
              <button
                type='button'
                className='btn btn-outline-secondary me-2 btn-sm'
                onClick={() => setShowAdoptModal(true)}
              >
                Adopt a signature
              </button>
            </>
          )}
        </div>
        <div className='border-grey border-bottom '>
          <SignatureCanvas
            ref={sign}
            onEnd={() => {
              setSignValid('');
            }}
            canvasProps={{
              width: 400,
              height: 200,
              style: { touchAction: 'none' },
            }}
          />
        </div>
        <div className='p-2 botder-grey border-bottom'>
          <p className='mb-0 fs-6'>Signed By</p>
          <h5 className='mb-0 mt-1'>{name}</h5>
        </div>
        {signValid == 'Rejected' ? (
          <div className='file-error-contract rounded ml-2 mt-2'>
            <span className='error-span'>Please sign in the canvas above.</span>
          </div>
        ) : null}
        <div className='p-2'>
          <div className='form-check mb-3'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              id={'terms'}
              onChange={(e) => {
                return e.target.checked ? setTermsValid('') : null;
              }}
            />
            <label className='form-check-label italic' htmlFor='terms'>
              I hearby agree to and accept the terms and conditions explained in
              the document(s) above.
            </label>
            {termsValid == 'Rejected' ? (
              <div className='file-error-contract rounded'>
                <span className='error-span'>Please Accept the Terms.</span>
              </div>
            ) : null}
          </div>
          <input
            type='button'
            className='btn btn-primary me-2 btn-sm'
            value='Sign Documents'
            disabled={save}
            onClick={() => {
              saveSign(sign);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SignCanvas;
